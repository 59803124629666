<template>
  <div class="pa-6">
    <v-card>
      <v-card-text>
        <div>
          scenario:<br />
          1. 冷氣控制: 冷氣開機<br />
          2. 冷氣運轉模式調整: 調溫度，間格30秒，轉送風，間格30秒，轉冷氣<br />
          3. 電驛控制: 接上電驛<br />
          4. 電驛控制: 斷開電驛<br />
          5. 排程控制:
          2分鐘後開電驛、開冷氣、冷氣模式、溫度28度，5分鐘後關冷氣、斷開電驛<br />
        </div>
        <v-row class="mt-10" align="end">
          <v-col cols="auto">
            <v-select
              hide-details
              v-model="vendor"
              :items="vendorOptions"
              label="請選擇環境"
            ></v-select>
          </v-col>
          <v-col cols="auto">
            <v-select
              hide-details
              v-model="scenario"
              :items="scenarioOptions"
              label="請選擇scenario"
            ></v-select>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" @click="sendScenario">送出</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import settings from '@/settings'

export default {
  name: 'TestScenario',
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {
      overlay: false,
      vendor: null,
      scenario: null,
      vendorOptions: [
        {
          text: '遠傳環境',
          value: 'w5PEp4pVDtq1nU0edJu7'
        },
        {
          text: '威力環境',
          value: 'xb3rHXJVU8H9mzVWBPOM'
        }
      ],
      scenarioOptions: [
        {
          text: '1. 冷氣控制: 冷氣開機冷氣開機',
          value: 1
        },
        {
          text: '2. 冷氣運轉模式調整: 調溫度，間格30秒，轉送風，間格30秒，轉冷氣冷氣開機',
          value: 2
        },
        {
          text: '3. 電驛控制: 接上電驛冷氣開機',
          value: 3
        },
        {
          text: '4. 電驛控制: 斷開電驛冷氣開機',
          value: 4
        },
        {
          text: '5. 排程控制: 2分鐘後開電驛、開冷氣、冷氣模式、溫度28度，5分鐘後關冷氣、斷開電驛冷氣開機',
          value: 5
        }
      ]
    }
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    sendScenario() {
      if (!this.vendor || !this.scenario) {
        alert('請選擇環境及scenario')
        return
      }
      this.overlay = true
      axios
        .post(
          settings.api.fullPath +
            `/ems/vendor/oring/${this.vendor}/${this.scenario}`,
          {}
        )
        .then((response) => {
          console.log(response)
          alert(response.data)
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.overlay = false
        })
    }
  }
}
</script>

<style></style>
